import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import {
  ArticleContainer,
  CopyText,
  Wrapper,
  ArticleUl,
  ArticleLi,
  Navigation,
  ArticleWrapper,
  ArticleDetailsWrapper,
  Header,
  Headline,
  NavWrapper,
  FlexContainer,
  GatsbyImg,
  FlexItem,
  InnerFlexItem,
  CategoryTitle,
  CustomLink,
  Text,
} from "../styles/components/products"

const Tische = ({ data }) => {
  const content = data.allWpPage.nodes[0]
  return (
    <Layout>
      <SEO 
    //   title={content.title} 
      />
      <ArticleContainer>

        {/* <Header>
          <Headline>Unsere 
              {content.title}
              </Headline>
          <CopyText>
              {content.pageTypeProduktUebersicht.bodycopy}
              </CopyText>
        </Header> */}

        <Wrapper>
    
          <Navigation>
            <NavWrapper>
              <ul>
                <li>
                  <Link></Link>
                </li>
                <li>
                  <Link></Link>
                </li>
                <li>
                  <Link></Link>
                </li>
              </ul>
            </NavWrapper>
          </Navigation>

          <FlexContainer>
          <Headline>
              {content.title}
              </Headline>

{content.pageTypeProductCategories.kategorien.map(i => (
  <InnerFlexItem>

    <FlexItem>
      <GatsbyImg fluid={i.image.localFile.childImageSharp.fluid} />
    </FlexItem>
    <FlexItem>
      <div>
      <CategoryTitle>{i.category}</CategoryTitle>
      <Text>{i.bodycopy}</Text>
      <CustomLink to={i.link.slug}>{`Alle ${i.category}`}</CustomLink>
      </div>
    </FlexItem>
 </InnerFlexItem>
))}

</FlexContainer>

        </Wrapper>
      </ArticleContainer>
    </Layout>
  )
}

export default Tische

export const query = graphql`
query {
    allWpPage(filter: {title: {eq: "Tische"}}) {
      nodes {
        title
        slug
        pageTypeProductCategories {
          kategorien {
            category
            bodycopy
            link {
              ... on WpPage {
                slug
              }
            }
            image {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
